@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


@tailwind base;
@tailwind components;
.btn-blue {
  @apply bg-blue-500 text-white font-bold py-2 px-4 rounded;
}

.btn-blue:hover {
  @apply bg-blue-700 border-l-8;
}


@tailwind utilities;
@layer base {
  :root {
    --color-primarylight: #B2DFDB;
    --color-primary: #009688;
    --color-primarydark: #00796B;
    --color-secondary: #965318;
    --color-wa: #22BFA5;
    --color-primaryadmin: #0ea5e9;
    --color-li-light: #CCFBF1;
    /* ... */
  }
  .toggle-check:checked~label div.toggle-dot {
    @apply translate-x-3;
  }
}

@layer components {
  .label-form {
    @apply text-sm font-semibold text-primarytext w-3/12;
  }
  .input-div {
    @apply w-9/12;
  }
  .input-form {
    @apply w-full text-sm font-normal text-primarytext border-b;
  }
  .input-form:focus {
    @apply outline-none border-blue-500 border-b-2 ;
  }
  .toggle-checkbox:checked {
    @apply right-0 border-blue-400;
    right: 0;
    border-color: #68D391;
  }
  .toggle-checkbox:checked+.toggle-label {
    @apply bg-blue-400;
    background-color: #68D391;
  }

  .sidebar-link {
    @apply hover:text-white py-2 space-x-2;
  }

  .thead-admin {
    @apply bg-gray-100 shadow;
  }
}